<template>
  <FormCreateComponent
    v-if="accessPermissions('ticket.store')"
  />
</template>

<script>
import FormCreateComponent from './components/FormCreateComponent.vue'
import permissions from '@/libs/permissions'

export default {
  name: 'TicketCreatePage',
  components: {
    FormCreateComponent,
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
  },
}
</script>
